/* .BlogDetail {
    padding: 150px;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .BlogDetail h1 {
    font-size: 2.5em;
    color:rgba(0, 0, 255, 0.589);
    margin-bottom: 20px;
  }
  
  .DetailImage {
    width: 100%;
    max-width: 800px;
    height: auto;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  
  .BlogDetail p {
    font-size: 1.2em;
    color: #555;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
  }

  .BlogDetail button{
    padding: 10px 20px;
    color: white;
    background-color: rgba(0, 0, 255, 0.5);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 50px;
  }

  .BlogDetail button:hover{
    background-color:rgba(0, 0, 255, 0.7);
  }
  
  @media (max-width: 768px) {
    .BlogDetail h1 {
      font-size: 2em;
    }
  
    .BlogDetail p {
      font-size: 1em;
    }
    .BlogDetail{
      padding: 100px 20px;
    }
  }
  
  @media (max-width: 480px) {
    .BlogDetail h1 {
      font-size: 1.5em;
    }
  
    .BlogDetail p {
      font-size: 0.9em;
    }
  }
   */


   .BlogDetail {
    padding: 150px;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .BlogDetail h1 {
    font-size: 2.5em;
    color: rgba(0, 0, 255, 0.589);
    margin-bottom: 20px;
  }
  
  .DetailImage {
    width: 100%;
    max-width: 800px;
    height: auto;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  
  .BlogDetail p {
    font-size: 1.2em;
    color: #555;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
  }
  
  .BlogDetail button {
    padding: 10px 20px;
    color: white;
    background-color: rgba(0, 0, 255, 0.5);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 50px;
  }
  
  .BlogDetail button:hover {
    background-color: rgba(0, 0, 255, 0.7);
  }
  
  /* .ShareButton {
    margin-top: 100px;
  } */
  
  @media (max-width: 768px) {
    .BlogDetail h1 {
      font-size: 2em;
    }
  
    .BlogDetail p {
      font-size: 1em;
    }
    .BlogDetail {
      padding: 100px 20px;
    }
  }
  
  @media (max-width: 480px) {
    .BlogDetail h1 {
      font-size: 1.5em;
    }
  
    .BlogDetail p {
      font-size: 0.9em;
    }
  }
  