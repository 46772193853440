.my-courses-page {
    background-color: #f9f9f9;
    /* padding: 20px; */
    border-radius: 8px;
    width: 100%;
  }
  
.my-courses-page h2{
  color: rgba(0, 0, 255, 0.7);
  text-align: center;
}

  .my-courses-page ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
  }
  
  .my-course{
    margin: 10px 0;
    padding: 10px;
    background-color: #e9ecef;
    border-radius: 4px;
    display: flex;
    /* gap: 100px; */
    color: blue;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    gap: 10px;
    width: 100%;
    padding: 10px 20px;
  }
  
  .my-course img{
    width: 30%;
    border-radius: 5px;
  }

  .my-course button{
    background-color: rgba(0, 0, 255, 0.5);
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    border: none;
    font-size: 0.7rem;
  }

  .my-course button:hover{
    background-color: rgba(0, 0, 255, 0.7);
  }

  .OneCourse{
    display: flex;
    margin: 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color:lightgray;
    padding: 10px;
    border-radius: 10px;
    flex-direction: column;
    gap: 10px;
  }


  @media screen and (max-width:768px) {
    .my-course{
      flex-direction: column;
      justify-content: center;
      /* gap: 0px;  */
    }
    .OneCourse{
      margin: 0px;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width:428px) {
    .OneCourse p{
      font-size: 0.8rem;
      margin: 0px 2px;
      /* width: 30%; */
      /* background-color: red; */
    }
  }