.AllCoursesHeader{
    background-color: gray;
    padding: 100px 0px;
    justify-content: center;
    padding-top: 120px;
    background-image: url("../Images/coursePageImg.jpeg");
    background-position: center;
    position: relative;
    width: 100%;
}

.AllCoursesHeader::before{
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
}