.LatestCourses {
    /* padding: 20px; */
    padding: 100px 20px;
    padding-top: 200px;
    /* background-color: #f9f9f9; */
    /* font-family: Arial, sans-serif; */
    /* background-color: rgb(235, 235, 255); */
    
  }
  
  .LatestCoursesHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    padding: 0px 40px;

  }
  
  .LatestCoursesHeader h1 {
    font-size: 2em;
    color:rgba(0, 0, 255, 0.7);
    margin: 10px 0;
  }
  
  .LatestCoursesHeader button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .LatestCoursesHeader button:hover {
    background-color: #0056b3;
  }
  
  .LatestCoursesBody {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  
  .Card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 290px;
    margin: 10px;
    transition: transform 900ms ease;
    
  }
  
  .Card:hover {
    transform: translateY(-10px);
  }
  
  .CardUp img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .CardDown {
    padding: 20px;
    /* text-align: center; */
  }
  
  .CardDown h4 {
    /* font-size: 1.5em; */
    color:rgb(1, 1, 100);
    margin-bottom: 10px;
  }
  
  .CardDown p {
    font-size: 1.2em;
    /* color: #007BFF; */
    color:rgb(1, 1, 100);
    margin-bottom: 20px;
    font-weight: 500;
  }
  
  .CardDown button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    /* border: 1px solid #007BFF; */
    border: 1px solid rgb(0, 0, 100);
    background-color: white;
    /* color:#007BFF; */
    color: rgb(1, 1, 100);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .CardDown button:hover {
    background-color:rgb(0, 0, 100,0.7);
    color: white;
    border: none;
  }
  
  .CardDown button svg {
    margin-right: 10px;
  }

  .CardDownAuthor{
    display: flex;
    gap:5px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
  }

  /* .CardDown span{
    color: gray;
  } */

  .CardDownAuthorLeft{
    padding: 5px 10px;
    border-radius:50px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 255, 0.2);
    color: white;
    /* margin-bottom: 10px; */
  }

  .CardDownAuthorLeft span{
    color: white;
    /* padding: 20px; */
  }
  
  .CardDownAuthorRight{
    color: gray;
  }

  .CardButtonAndShareWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .CardShare{
    padding: 5px;
    background-color: rgba(0, 0, 255, 0.2);
    cursor: pointer;
    color: rgba(0, 0, 100,0.6);
    /* color: white; */
    font-size: 1.5rem;
    border-radius: 50px;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    .LatestCoursesHeader {
      /* flex-direction: column; */
      align-items: flex-start;
    }
  
    .LatestCoursesHeader h1 {
      font-size: 1.8em;
    }
  
    .LatestCoursesHeader button {
      margin-top: 10px;
    }
  
    .CardDown h1 {
      font-size: 1.3em;
    }
  
    .CardDown p {
      font-size: 1em;
    }
  }
  
  @media (max-width: 480px) {
    .LatestCoursesHeader h1 {
      font-size: 1.5em;
    }
  
    .Card {
      width: 40%;
      max-width: none;
    }
  
    .CardUp img {
      height: 100px;
    }

    .CardDown{
      padding: 10px;
    }
  
    .CardDown h1 {
      font-size: 0.5rem;
      margin-bottom: 5px;
    }

    .CardDown h4{
      font-size: 0.9rem;
      margin-bottom: 5px;
    }
  
    .CardDown p {
      font-size: 0.8rem;
      margin-bottom: 5px;
    }
    .LatestCourses{
      padding-top: 200px;
      padding-left: 0px;
      padding-right: 0px;
      /* padding: 0px 0px; */
    }
    .LatestCoursesBody{
      gap:0px;
    }

    .CardDown button{
      padding: 5px 10px;
    }

    .CardButtonAndShareWrap{
      gap:10px;
    }

    .CardDownAuthor{
      display: none;
    }

    .LatestCoursesHeader{
      text-align: center;
      justify-content: center;

    }

    .LatestCoursesHeader button{
      position: absolute;
      bottom: 30px;
    }
    .LatestCourses{
      position: relative;
      padding-top: 80px;
    }
  }
  