  /* src/CSS/ProfilePage.css */
  
  .profile-page {
    background-color:rgba(211, 211, 211, 0.4);
    padding: 20px;
    border-radius: 8px;
  }
  
  .profile-page h2{
    text-align: center;
    color: rgba(0, 0, 255, 0.541);
  }

  .profile-page p {
    margin: 10px 0;
  }
  
