/* DeliveryDetail.css */

.delivery-detail-page {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-top: 100px;
    
  }
  
  .delivery-detail-page h2 {
    text-align: center;
    margin-bottom: 20px;
    color: rgba(0, 0, 255, 0.5);
  }
  
  .delivery-detail-form-group {
    margin-bottom: 15px;
  }
  
  .delivery-detail-form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .delivery-detail-form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .delivery-detail-page button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color:rgba(0, 0, 255, 0.5);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delivery-detail-page button[type="submit"]:hover {
    background-color: rgba(0, 0, 255, 0.7);
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    .delivery-detail-page {
      margin: 10px;
      padding: 10px;
      margin-top: 150px;
      margin-bottom: 100px;
    }
  
    /* .form-group input {
      padding: 8px;
    }
  
    button[type="submit"] {
      padding: 8px;
    } */
  }
  