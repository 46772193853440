/* src/CSS/Dashboard.css */

.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding: 100px 20px;

  }
  
  .dashboard-nav {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .dashboard-nav button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .dashboard-nav button:hover {
    background-color: #0056b3;
  }
  
  .dashboard-content {
    width: 100%;
    max-width: 800px;
  }


  .AdminDashboard{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
    /* background-color: red; */
    /* padding: 100px 0px; */

  }

  .AdminDashboardMenu{
    width: 30%;
    background-color: rgba(211, 211, 211, 0.5);
    padding: 10px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    gap: 5px;
    /* height: 500px; */
    min-height: 100%;
    padding-top: 100px;
  }

  .AdminDashboardMenuP{
    cursor: pointer;
    color: blue;
    font-weight: 500;
    /* text-decoration: underline; */
    padding: 5px 10px;
    border-radius: 5px;
  }

  .AdminDashboardMenuPActive{
    cursor: pointer;
    color: white;
    background-color: rgba(0, 0, 255, 0.4);
    /* font-weight: 500; */
    /* text-decoration: underline; */
    padding: 5px 10px;
    border-radius: 5px;
  }

  .AdminDashboardContentWrap{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .dashboard-nav {
      flex-direction: column;
      width: 100%;
    }
  
    .dashboard-nav button {
      width: 100%;
      text-align: center;
    }
  }
  
  /* src/CSS/MyCoursesPage.css */
  
  