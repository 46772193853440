/* AboutUs.css */
.about-us {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 50px 20px;
}

.about-us h2 {
    margin-bottom: 30px;
    color: rgba(0, 0, 255, 0.473);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 2rem;
    border-top: 2px solid rgba(0, 0, 255, 0.2);
    padding-top: 50px;
    width: 100%;
    text-align: center;
}

.about-item {
    position: relative;
    padding: 20px;
}

.about-image {
    width: 100%;
    height: 250px;
    border-radius: 10px;
    filter: blur(2px); /* Optional: Slight blur for the image */
    transition: filter 0.3s;
}

.about-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    width: 65%;
    border-radius: 10px;
    max-width: 80%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    /* padding-left: 30px; */
}

.about-content h3 {
    margin-bottom: 15px;
}

.about-content p {
    margin-bottom: 0;
}

/* Responsive styling */
@media (max-width: 768px) {
    .about-content {
        max-width: 90%;
        padding: 15px;
    }

    .about-content h3 {
        font-size: 1.2em;
    }
}

@media screen and (max-width:428px) {
    .about-us h2{
        font-size: 1.5rem;
    }
    .about-us{
        padding: 0px 0px;
    }
}