/* src/components/Users.css */
/* .users-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .users-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    color:rgba(0, 0, 255, 0.5);
  }
  
  .users-search-bar {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
    box-sizing: border-box;
  }
  
  .users-table-container {
    overflow-x: auto;
  }
  
  .users-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .users-thead {
    background-color: #f4f4f4;

  }
  
  .users-th, .users-td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .users-th {
    background-color: #f9f9f9;
    color: rgba(0, 0, 255, 0.7);
  }
  
  .users-tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  

  @media (max-width: 768px) {
    .users-table {
      font-size: 0.9em;
    }
  } */
  

  /* src/CSS/Users.css */
.users-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 5px; */
  font-family: Arial, sans-serif;
  padding-top: 100px;
  height: 500px;
  overflow-y: scroll;
  width: 100%;
}

.users-heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  color: rgba(0, 0, 255, 0.5);
}

.users-search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
  box-sizing: border-box;
}

.users-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.user-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  background-color: #f9f9f9;
}

.user-detail {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.user-label {
  font-weight: bold;
  color: rgba(0, 0, 255, 0.7);
}

.user-value {
  margin-top: 5px;
  color: #333;
}

/* Responsive design */
@media (max-width: 768px) {
  .users-heading {
    font-size: 1.5em;
  }

  .users-search-input {
    font-size: 0.9em;
  }

  .user-card {
    padding: 10px;
  }
}
