.SearchPageHeader{
    background-color: gray;
    padding: 100px 0px;
    justify-content: center;
    padding-top: 120px;
    background-image: url("../Images/coursePageImg.jpeg");
    background-position: center;
    position: relative;
    width: 100%;
}

.SearchPageHeader::before{
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
}

.NoResultWrap{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
    color: rgba(0, 0, 255, 0.699);
    flex-direction: column;
    gap:30px;
}

.NoResultWrap button{
    border: none;
    padding: 10px 20px;
    color: white;
    background-color: rgba(0, 0, 255, 0.5);
    cursor: pointer;
    border-radius: 10px;
}

.NoResultWrap button:hover{
    background-color: rgba(0, 0, 255, 0.7);
}