.become-instructor-page {
    padding: 100px 20px;
    /* padding: 20px; */
    max-width: 1200px;
    margin: 0 auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: relative;
}

.BecomeAffiliatePage{
    background-image: url("../Images/affiliateImg2.png");
    background-position: center;
    background-size: cover;
    position: relative;
}

.BecomeAffiliatePage::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
}

.page-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    color: rgba(0, 0, 255, 0.5);
}

.content-section {
    text-align: center;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner-image {
    width: 50%;
    height: auto;
    /* border-radius: 8px; */
    margin-bottom: 20px;
}

.link {
    /* background-color: #007bff; */
    /* border: 1px solid #007bff; */
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    /* padding: 5px 10px; */
    color:blue ;
    border-radius: 10px;
}

.link:hover {
    text-decoration: none;
}

.apply-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 20px;
}

.apply-button:hover {
    background-color: #0056b3;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
    text-align: left;
}

.modal-content h2{
    color: rgba(0, 0, 255, 0.5);
}

.close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 2rem;
    cursor: pointer;
    color: #333;
}

.faq-section {
    margin-bottom: 40px;
    display: flex;
    gap: 20px;
    text-align: center;
    background-color: rgba(0, 0, 255, 0.1);
    padding: 20px;
}

.faq-section h2{
    color: blue;
}

.faq-item {
    margin-bottom: 20px;
}

.faq-item h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.instructors-section {
    text-align: center;
}

.instructors-section h2{
    color: rgba(0, 0, 255, 0.5);
    margin-bottom: 10px;
}

.instructors-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.instructor-card {
    max-width: 200px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    padding: 10px;
}

.instructor-card h3{
    color: rgba(0, 0, 255, 0.5);
}

.instructor-image {
    width: 100%;
    height: auto;
    border-radius: 50%;
    margin-bottom: 10px;
}


.CustomBackButtonWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 50px;
}

.CustomBackButtonWrap button{
    padding: 10px 20px;
    border-radius: 10px;
    border:none ;
    color: blue;
    border: 1px solid blue;
    cursor: pointer;
    background-color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

}

.CustomBackButtonWrap button:hover{
    /* background-color: rgba(0, 0, 255, 0.7); */
}

@media screen and (max-width:884px) {
    .faq-section{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }    
    .faq-item{
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .page-title {
        font-size: 2rem;
    }

    .faq-section,
    .instructors-section {
        /* text-align: left; */
    }
}
