/* Wrapper for the entire content */
.youtube-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    /* padding: 30px; */
    padding: 50px 30px;
    background-color: #f9f9f9;
    border-radius: 10px;
  }

  .youtube-wrapper p{
    font-style: italic;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .youtube-wrapper {
      /* padding: 20px; */
    }
  }
  
  @media (max-width: 480px) {
    .youtube-wrapper {
      /* padding: 15px; */
    }
  }
  
  /* Heading styles */
  .youtube-wrapper h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ff0000; /* Youtube-like color */
  }
  
  /* Paragraph styles */
  .youtube-wrapper p {
    font-size: 1.2rem;
    margin-top: 10px;
  
  }
  
  /* Image styles with animation */
  .youtube-image {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
    animation: flip 3s ease-in-out infinite;
  }
  
  /* Responsive image scaling */
  @media (max-width: 768px) {
    .youtube-image {
      width: 150px;
    }
  }
  
  @media (max-width: 480px) {
    .youtube-image {
      width: 120px;
    }
  }
  
  /* Keyframes for flip animation */
  @keyframes flip {
    0% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
  