
.registration-form-container {
    padding: 100px 20px;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .form-title {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
    color: rgba(0, 0, 255, 0.5);
  }
  
  .registration-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-groupInst {
    margin-bottom: 15px;
    color: rgba(0, 0, 255, 0.7);
    
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
  }
  
  textarea {
    resize: vertical;
  }
  
  .consent {
    display: flex;
    align-items: center;
  }
  
  .consent input {
    margin-right: 10px;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 20px;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .registration-form-container {
      /* padding: 100 10px; */
    }
  
    .form-title {
      font-size: 1.5rem;
    }
  }
  