/* CourseDetail.css */
.ECourseDetail {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 100px;
  }

  .ECourseDetail label{
    color: rgba(0, 0, 255, 0.7);
  }
  
  .ECourseImage {
    width: 100%;
    max-width: 600px;
  }
  
  .ECourseInfo {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
  }
  
  .ECourseTitle {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
    color: rgba(0, 0, 255, 0.719);
  }


  .ECourseInfo input{
    margin-bottom: 10px;
  }
  
  .ECoursePrice, .ECourseType, .ECourseDescription {
    margin: 10px 0;
  }
  
  .ECourseOutlineHeader {
    margin-top: 20px;
    color: rgba(0, 0, 255, 0.705);
  }
  
  .ECourseOutline {
    list-style-type: none;
    padding: 0;
    
  }
  
  .CourseChapter {
    background: #e0e0e0;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
  }

  .CourseChapter h3{
    color: rgba(0, 0, 0, 0.7);
    
  }

  .CourseChapter p{

    /* cursor: pointer; */
    /* color: blue; */
  }

  .CourseLesson{
    margin-bottom: 10px;
  }
  
  .EAddToCartButton {
    display: flex;
    align-items: center;
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .EShareIcon {
    font-size: 24px;
    margin-top: 20px;
    cursor: pointer;
    color: rgba(0, 0, 255, 0.719);
  }
  
  @media (max-width: 768px) {
    .ECourseDetail {
      /* padding-top: 100px; */
      /* padding: 10px; */
    }
  
    .ECourseInfo {
      padding: 10px;
    }
  }
  