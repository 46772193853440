/* CartPage.css */

.cart-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding-top: 100px;
   
  }
  
  .cart-page h2 {
    display: flex;
    align-items: center;
    /* font-size: 24px; */
    justify-content: center;
    color:rgba(0, 0, 255, 0.5);
    margin-bottom: 20px;
    gap: 5px;
  }
  
  .cart-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .cart-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
  }
  
  .cart-item img {
    max-width: 100px;
    height: auto;
    border-radius: 8px;
  }
  
  .item-details {
    flex: 1;
    padding-left: 20px;
  }
  
  .item-details h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .item-details p {
    font-size: 16px;
    color: #333;
  }
  
  .item-details button {
    padding: 8px 16px;
    background-color: #dc3545;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .item-details button:hover {
    background-color: #c82333;
  }
  
  .cart-summary {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .cart-summary h3 {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .checkout-button {
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .checkout-button:hover {
    background-color: #218838;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .cart-items {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  
    .cart-item {
      padding: 15px;
    }
  
    .cart-item img {
      max-width: 80px;
      margin-left: 18px;
    }
  
    .item-details h3 {
      font-size: 16px;
    }
  
    .item-details p {
      font-size: 14px;
    }
  
    .item-details button {
      padding: 6px 12px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 576px) {
    .cart-items {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  
    .cart-item {
      padding: 10px;
    }
  
    .cart-item img {
      max-width: 60px;
    }
  
    .item-details h3 {
      font-size: 14px;
    }
  
    .item-details p {
      font-size: 12px;
    }
  
    .item-details button {
      padding: 6px 12px;
      font-size: 12px;
    }
  
    .checkout-button {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  