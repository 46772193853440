.CourseVideoPlayer{
    width: 600px;
    height: 350px;
}

@media screen and (max-width:824px){
        
}

@media screen and (max-width:724px) {
    .CourseVideoPlayer{
      width: 100%;
      height: 300px;
    }
}

@media screen and (max-width:428px) {
  .CourseVideoPlayer{
    width: 100%;
    height: 250px;
  }
}

@media screen and (max-width:360px) {
  .CourseVideoPlayer{
    width: 100%;
    height: 220px;
  }
}

@media screen and (max-width:320px) {
  .CourseVideoPlayer{
    width: 100%;
    height: 200px;
  }
}