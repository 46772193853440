.Blogs {
    padding: 100px;
    /* background-color: #f9f9f9; */
    /* background-color: rgba(211, 211, 211, 0.3); */
    /* font-family: Arial, sans-serif; */
  }
  
  .Blogs h1 {
    font-size: 2.3rem;
    color: rgba(0, 0, 255, 0.473);
    margin-bottom: 20px;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  .BlogsBody {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .BlogCard {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 300px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .BlogCard:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .BlogImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .BlogContent {
    padding: 20px;
    position: relative;
  }
  
  .BlogContent h2 {
    font-size: 1.4rem;
    color: rgba(0, 0, 255, 0.5);
    margin-bottom: 10px;
  }
  
  .BlogContent p {
    /* font-size: 1.2em; */
    color: #555;
    margin-bottom: 20px;
    font-size: 0.9rem;
  }
  
  .ShareButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    color:rgba(0, 0, 255, 0.3);
  }

  @media screen and (max-width:884px) {
    .BlogCard:nth-child(3){
      display: none;
    }
  }
  
  @media (max-width: 800px) {
    .Blogs{
      padding: 50px;
    }
    .Blogs h1 {
      font-size: 2em;
    }
  
    .BlogCard {
      width: 40%;
      /* max-width: 250px; */
    }
  
    .BlogContent h2 {
      font-size: 1.3em;
    }
  
    .BlogContent p {
      font-size: 1em;
    }
  }
  
  /* @media (max-width: 480px) {
    .Blogs h1 {
      font-size: 1.5em;
    }
  
    .BlogCard {
      width: 90%;
    }
  
    .BlogContent h2 {
      font-size: 1.2em;
    }
  
    .BlogContent p {
      font-size: 0.9em;
    }
  }
   */


   @media screen and (max-width:428px) {
      .BlogCard{
        width: 100%;
        
      }
      .Blogs{
        padding: 40px 10px;
      }
      .Blogs h1{
        font-size: 1.5rem;
      }
      .BlogCard img{
        height: 100px;
        
      }

      .BlogsBody{
        gap:40px
      }
   }