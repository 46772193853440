/* LandingPage.css */
.landing-page {
    font-family: Arial, sans-serif;
    
}

header, footer {
    background: #333;
    color: white;
    padding: 20px;
    text-align: center;
}

section {
    padding: 50px 20px;
    text-align: center;
}


.partners h2{
    color: rgba(0, 0, 255, 0.5);
    margin-bottom: 20px;
}

.instructor-card, .faq-item, .partner-logo {
    background: #f8f8f8;
    border-radius: 10px;
    padding: 20px;
    width: 30%; /* Adjust width based on number of items per row */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}



.instructors-container, .faq-container, .partners-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.faq{
    /* background-color: rgba(173, 216, 230, 0.2); */
    background-color: rgb(250, 250, 255);
    padding-top: 100px;
}
.faq h2{
    color: rgba(0, 0, 255, 0.521);
    font-size: 2rem;

}

.faq-item h3{
    color: rgba(0, 0, 255, 0.7);
}

.instructor-card img, .partner-logo img {
    border-radius: 10px;
    width: 100%;
    height: auto;
}

.instructor-card h3, .faq-item h3 {
    margin: 15px 0 10px;
}

.instructor-card p, .faq-item p {
    color: #666;
}

.instructor-card:hover, .faq-item:hover {
    transform: translateY(-10px);
}

.call-to-action{
    background-image: url("../Images/career\ img\ 2.jpeg");
    background-position: center;
    margin-top: 50px;
    position: relative;
}

.call-to-action::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.377);
}

.call-to-action h2{
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 2.5rem;
    position: relative;
}

.call-to-action button{
    border: none;
    padding: 20px;
    background-color: rgba(0, 0, 255, 0.5);
    color: white;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
}

.call-to-action button:hover{
    background-color: rgba(0, 0, 255, 0.7);
}


/* Responsive styling */
@media (max-width: 1200px) {
    .instructor-card, .faq-item, .partner-logo {
        width: calc(33.333% - 20px); /* Three in a row */
    }
}

@media (max-width: 768px) {
    .instructor-card, .faq-item, .partner-logo {
        width: calc(50% - 20px); /* Two in a row */
    }
}

@media (max-width: 480px) {
    .instructor-card, .faq-item, .partner-logo {
        width: 100%; /* One in a row */
    }
}

@media screen and (max-width:428px) {
    .call-to-action h2{
        font-size: 1.5rem;
    }
}
