.Features {
  width: 100%;
    padding: 20px;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
    text-align: center;
    position: relative;
    background-color: rgba(0, 0, 255, 0.2);
  }
  

  
  .FeaturesBody, .CarouselWrap {
    /* display: flex; */
    width: 98%;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    position: absolute;
    top: -50px;
    /* left: 0; */
  
  }

  /* .CarouselWrap{
    width: 100%;
    position: absolute;
    top: -50px;
  } */
  
  .Feature {
    background-color: white;
    /* background-color: rgb(230, 240, 255); */
    /* background-color: rgba(0, 0, 255, 0.493); */
    /* border: 1px solid #ddd; */
    border-radius: 10px;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); */
    width: 200px;
    height: 150px;
    padding: 20px;
    margin: 10px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    color: blue;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  /* .Feature:nth-child(2){
    left:300px;

  }

  .Feature:nth-child(3){
    left:550px;
 
  }

  .Feature:nth-child(4){
    left:800px;

  }

  .Feature:nth-child(5){
    left: 1050px;
 
  } */
  
  .Feature:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    background-color: rgb(168, 168, 252);
    color: white;
  }
  
  .FeatureIcon {
    font-size: 2em;
    /* color: purple; */
    color: rgba(0, 0, 255, 0.5);
    /* color: white; */
    margin-bottom: 10px;
  }
  
  .Feature span {
    font-size: 1.2em;
    /* color:white; */
    display: block;
    margin-top: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .Features h1 {
      font-size: 2em;
    }
  
    .Feature {
      width: 150px;
      padding: 15px;
    }
  
    .FeatureIcon {
      font-size: 1.5em;
    }
  
    .Feature span {
      font-size: 1em;
    }
  }
  
  @media (max-width: 480px) {
    .Features h1 {
      font-size: 1.5em;
    }
  
    .Feature {
      width: 100px;
      padding: 10px;
    }
  
    .FeatureIcon {
      font-size: 1.2em;
    }
  
    .Feature span {
      font-size: 0.9em;
    }
  }
  
  @media screen and (max-width:464px) {
    .Feature{
      height: 100px;
      width: 150px;
  
    }
    .FeatureIcon{
      margin-bottom: 0px;
    }
  }

  @media screen and (max-width:375px) {
    .Feature{
      width: 140px;
    }
  }

  @media screen and (max-width:360px) {
    .Feature{
      width: 130px;
    }
  }

  @media screen and (max-width:320px) {
    .Feature{
      width: 110px;
    }
  }