
.Hero {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 550px;
    flex-direction: column;
    gap: 20px;
    position: relative;
    text-align: center;
    overflow: hidden;
}

.Hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../Images/businessman-working-laptop.jpg");
    background-position: center;
    background-size: contain; /* Changed to cover for a better fit */
    filter: blur(5px);
    z-index: -2; /* Ensure it stays behind the overlay */
}

.Hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* Dark overlay */
    z-index: -1; /* Ensure it stays behind the content but on top of the image */
}

/* Ensure text and other content stays on top */
.Hero > * {
    position: relative;
    z-index: 1;
}

/* Hero section text styles */
.Hero h1 {
    font-size: 3.3rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgba(255, 255, 255, 0.8);
}

.Hero p {
    font-size: 1.4rem;
    color: white;
}


.SearchWrap{
    width: 550px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SearchWrap input{
    outline: none;
    border: none;
    width: 350px;
    padding-left: 20px;
    color:rgba(0, 0, 0, 0.8)  ;
    /* font-size: 1rem; */
    background-color: transparent;
}

.SearchWrap button{
    padding: 15px 30px;
    border-radius: 50px;
    border: none;
    background-color: rgba(0, 0, 255, 0.507);
    color: white;
    cursor: pointer;
}

.SearchWrap button:hover{
    background-color: rgba(0, 0, 255, 0.7);
}

.SearchIcon{
    display: none;
}


@media screen and (max-width:768px) {
    .Hero h1{
        font-size: 2.7rem;
    }

    .SearchWrap{
        width: 80%;
    }
}

@media screen and (max-width:428px) {
    .SearchWrap{
        width: 90%;
    }
    .SearchWrap button{
        padding: 15px 15px;       
    }
    .Hero h1{
        font-size: 2rem;
    }
    .SearchIcon{
        display: block;
    }

    .SearchText{
        display: none;
    }
    .Hero p{
        padding: 0px 10px;
    }
}