.EditModal{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* min-height: 100%; */
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top:0px;
    left: 0px;
    justify-content: center;
    align-items:center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.EditModalContent{
    width: 80%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 50px;
    border-radius: 10px;
    overflow-y: scroll;
}

.EditModalContent label, .EditModal h2{
    color: rgba(0, 0, 255, 0.664);
}

.EditModalContent button{
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: rgba(0, 0, 255, 0.7);
    color: white;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 10px;
}


.EditModal2{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* min-height: 100%; */
    /* height: 1000px; */
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top:0px;
    left: 0px;
    justify-content: center;
    align-items:center;
    padding-top: 20px;
    padding-bottom: 20px;
}