/* .Testimonials {
    padding: 100px;
    background-color: rgba(255, 192, 203, 0.2);
    font-family: Arial, sans-serif;
    text-align: center;
    background-image: url("../Images/career\ img\ 2.jpeg");
  } */
  

  .Testimonials {
    position: relative;
    padding: 100px;
    background-color: rgba(255, 192, 203, 0.2);
    font-family: Arial, sans-serif;
    text-align: center;
    overflow: hidden;
}

.Testimonials::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../Images/career img 2.jpeg");
    background-size: cover;
    background-position: center;
    filter: blur(10px); /* Adjust the blur level as needed */
    z-index: -2;
}

.Testimonials::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the darkness level as needed */
    z-index: -1;
}

.Testimonials > * {
    position: relative;
    z-index: 1;
}




  .Testimonials h1 {
    font-size: 2.5em;
    /* color:rgba(0, 0, 255, 0.5); */
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 20px;
  }
  
  .Testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .TestimonialImage {
    width: 100px !important;
    height: 100px;
    border-radius: 50%;
    /* object-fit: cover; */
    margin-bottom: 20px;
    /* border: 3px solid #007BFF; */
  }
  
  .TestimonialContent {
    max-width: 600px;
  }
  
  .TestimonialContent p {
    font-size: 1.2em;
    /* color: #555; */
    color: white;
    margin-bottom: 10px;
    font-style: italic;
  }
  
  .TestimonialContent h3 {
    font-size: 1.5em;
    /* color: blueviolet; */
    color: white;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .Testimonials h1 {
      font-size: 2em;
    }
  
    .TestimonialContent p {
      font-size: 1em;
    }
  
    .TestimonialContent h3 {
      font-size: 1.2em;
    }
  }
  
  @media (max-width: 480px) {
    .Testimonials h1 {
      font-size: 1.5em;
    }
  
    .TestimonialContent p {
      font-size: 0.9em;
    }
  
    .TestimonialContent h3 {
      font-size: 1em;
    }
  }
  
  @media screen and (max-width:428px) {
    .Testimonials{
      padding: 0px;
      padding: 50px 0px;
    }
  }