.Footer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-color: rgb(0, 0, 100,0.7);
    color: white;
}

.FooterUp{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.FooterUp p{
    display: flex;
    width: 200px;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
}

.FooterLine{
    display: flex;
    width: 100%;
    background-color: lightgray;
    height: 2px;
    margin-top: 50px;
}

.FooterDown{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
    /* align-items: center; */
}

.QuickLinks{
    display: flex;
    flex-direction: column;
    gap: 5px
    ;
}

.QuickLinks p{
    cursor: pointer;
}

.QuickLinks p:hover{
    text-decoration: underline;
}

.PaymentMethods{
    display: flex;
    flex-direction: column;
    gap:10px;
}

.PaymentMethods p{
    font-size: 4rem;
}

.FooterLogo{
    display: flex;
    width: 100%;
    /* font-size: 2rem; */
}

.FooterLogo img{
    width: 5%;
}


.CopyRight{
    color:"white";
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; 
    margin-bottom: 30px;
    flex-direction: column;
    /* font-size: 1.2rem; */
    gap:8px;
    text-align: center;
  }
  

@media screen and (max-width:768px) {
  .FooterUp{
    flex-direction: column;
    gap:20px;
    align-items: flex-start;
    
  }   
  .FooterDown{
    flex-direction: column;
    gap: 40px;
  }
  .PaymentMethodIconsWrap{
    display: flex;
    gap: 20px;
   
  }
}

@media screen and (max-width:428px) {
    .FooterLogo img{
        width: 10%;       
    }
    .CopyRight{
        padding: 0% 3%;
      }
}