.ManualScrollToTop{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: blueviolet;
    color: white;
    border-radius: 50px;
    font-size: 1.5rem;
    position: fixed;
    bottom: 5%;
    right: 3.3%;
    cursor: pointer;
    padding: 5px;
    z-index: 9;
}