.instructor-section {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    padding: 40px;
    background-color: #f9f9f9;
    background-color: rgba(0, 0, 255, 0.1);
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* background-image: url("../Images/earnImg.png"); */
    background-size: cover;
    background-position: center;
    position: relative;
}



/* .instructor-section::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
} */

.instructor-content {
    max-width: 500px;
    padding: 20px;
    position: relative;
}

.instructor-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: rgba(0, 0, 255, 0.5);
    
}

.instructor-description {
    font-size: 1rem;
    margin-bottom: 20px;
    /* color: #555; */
}

.instructor-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
}

.instructor-button:hover {
    background-color: #0056b3;
}

.instructor-image img {
    max-width: 100%;
    height: auto;
    /* border-radius: 10px; */
    position: relative;
    /* border-radius: 50%; */
}



@media screen and (max-width: 884px) {
    .instructor-section {
        flex-direction: column-reverse;
        text-align: center;
    }
    .instructor-section2{
        flex-direction: column !important;
    }
    .instructor-image img {
        margin-top: 20px;
    }
}
