
  /* src/CSS/AllAffiliates.css */
.affiliates-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    /* padding: 5px; */
    font-family: Arial, sans-serif;
    padding-top: 100px;
    height: 500px;
    overflow-y: scroll;
    width: 100%;
  
}

.affiliates-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    color:rgba(0, 0, 255, 0.527);
}

.affiliates-search-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
    box-sizing: border-box;
}

.affiliates-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.affiliate-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    background-color: #f9f9f9;
}

.affiliate-detail {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.affiliate-label {
    font-weight: bold;
    color:rgba(0, 0, 255, 0.7);
}

.affiliate-value {
    margin-top: 5px;
    color: #333;
}

/* Responsive design */
@media (max-width: 768px) {
    .affiliates-heading {
        font-size: 1.5em;
    }

    .affiliates-search-input {
        font-size: 0.9em;
    }

    .affiliate-card {
        padding: 10px;
    }
}
