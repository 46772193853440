.Loading {
    text-align: center;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
  
  .Loading-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .Loading-logo {
      animation: Loading-logo-spin infinite 5s linear;
    }
  }
  
  .Loading-header {
    /* background-color: #282c34; */
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Loading-link {
    color: #61dafb;
  }
  
  @keyframes Loading-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  