.PrivacyPolicyContainerWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    text-align: center;
    padding-bottom: 50px;
    background-color: lightgray;
}


.PrivacyPolicy-container {

    max-width: 800px;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* margin: 20px auto; */
  }
  
  .PrivacyPolicy-title,
  .PrivacyPolicy-subtitle {
    /* color: #007BFF; */
    color: rgba(0, 0, 255, 0.5);
    margin-bottom: 10px;
  }
  
  .PrivacyPolicy-icon {
    /* color: #007BFF; */
    color: rgba(0, 0, 255, 0.5);
    margin-right: 10px;
    vertical-align: middle;
    font-size: 3rem;
  }
  
  .PrivacyPolicy-section {
    margin-bottom: 20px;
  }
  
  .PrivacyPolicy-list {
    list-style: none;
    padding-left: 0;
  }
  
  .PrivacyPolicy-list-item::before {
    content: "•";
    color: #007BFF;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  
  .PrivacyPolicy-link {
    color: #007BFF;
  }
  
  .PrivacyPolicy-link:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .PrivacyPolicy-container {
      padding: 30px;
    }
    .PrivacyPolicyContainerWrap{
        padding-left: 20px;
        padding-right: 20px;
    }
  }

  @media (max-width: 375px) {
    .PrivacyPolicy-container {
      padding: 25px;
    }
    .PrivacyPolicyContainerWrap{
        padding-left: 10px;
        padding-right: 10px;
    }
    .PrivacyPolicy-title{
        font-size: 1.5rem;
    }
  }
  