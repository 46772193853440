*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
  
}

/* .App{
display: flex;
justify-content: center;
align-items: center;
width: 100%;
} */

.swal2-popup {
  /* background-color: greenyellow; */
  /* font-size: 1.5vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 30%; */
  /* height: 70%; */
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999;
}

.swal2-popup .swal2-title {
  font-family: sans-serif;
  /* font-size: 1.5rem; */
  /* font-size: 4vw; */
  color: blue;   
}

.swal2-popup .swal2-text {
  font-family: sans-serif;
  /* font-size: 0.5rem; */
} 


@media screen and (max-width:884px) {
  .swal2-popup{
    /* font-size: 3vw; */
    /* width: 50%; */
  } 
}

@media screen and (max-width:428px) {
  .swal2-popup{
    /* font-size: 4vw; */
    /* width: 70%; */
  } 
}


/* whats app icon styling */
.WhatsAppIcon{
  position: fixed;
  z-index: 99;
  /* bottom: 50px; */
  /* right: 50px; */
 bottom: 15%;
 right: 2%; 
  width: 70px;
  box-shadow: 0px 4px 5px;
  border-radius: 50%;
  cursor: pointer;
}

/* @media screen and (max-width:768px) {
  .WhatsAppIcon{
      width: 50px;
      bottom: 120px;
      right: 30px;
  }

  
}

@media screen and (max-width:428px) {
  .WhatsAppIcon{
      bottom: 5%;
      right: 5%;
  }
} */



.SideLogo{
  width: 100px;
  position: fixed;
  bottom: 25%;
  right: 1%;
  animation: flip 4s infinite;
}

@keyframes flip {
  0% {
      transform: rotateY(0deg);
  }
  100% {
      transform: rotateY(360deg);
  }
}


.SideLogo2{
  width: 100px;
  position: fixed;
  bottom: 25%;
  left: 1%;
  animation: flip 10s infinite;
}

@keyframes flip {
  0% {
      transform: rotateY(0deg);
  }
  100% {
      transform: rotateY(360deg);
  }
}