/* HowItWorks.css */
.how-it-works {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 50px 20px;
    background-color: #f8f8f8;
}

.how-it-works h2 {
    margin-bottom: 30px;
    color: rgba(0, 0, 255, 0.582);
}

.stages-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.stage {
    display: flex;
    align-items: center;
    margin: 10px;
    position: relative;
}

.content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 200px;
    text-align: left;
}

.content h3{
    color: rgba(0, 0, 255, 0.521);
}

.arrow {
    font-size: 2em;
    margin: 0 10px;
    color: blue;
    /* color: #333; */
}

/* Responsive styling */
@media (max-width: 800px) {
    .stage {
        flex-direction: column;
        align-items: center;
    }

    .arrow {
        display: none;
    }
}
