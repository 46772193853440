/* src/CSS/OrderSummary.css */

.order-summary-page {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    padding: 100px 20px;
  }

  .order-summary-page h2{
        color: rgba(0, 0, 255, 0.5);
        margin-bottom: 20px;
        text-align: center;
  }

  .delivery-details,
  .cart-details {
    margin-bottom: 20px;
  }
  
  .delivery-details h3,
  .cart-details h3 {
    margin-bottom: 10px;
    color: blue;
  }
  
  .cart-details ul {
    list-style-type: none;
    padding: 0;
  }
  
  .cart-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
    /* background-color: red; */
  }
  
  .cart-item p {
    margin: 5px 0;
  }
  
  .cart-total {
    text-align: right;
    margin-top: 20px;
    color: rgba(0, 0, 255, 0.753);
  }
  
  .pay-now-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    display: block;
    width: 100%;
    text-align: center;
  }
  
  .pay-now-button:hover {
    background-color: #0056b3;
  }

  .PaymentCurrencySelect{
    align-items: center;
    text-align: center;
    align-self: center;
    display: flex;
    cursor: pointer;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 20px;
    outline: none;
    color: blue;
    font-weight: bold;
  }


  .OrderSummaryPageSelect{
    width: 100%;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 20px;
    outline: none;

  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .cart-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .cart-item p {
      width: 100%;
      text-align: left;
    }
  
    .cart-total {
      text-align: left;
    }
  }
  