/* Categories.css */
.categories {
    /* border-top: 3px solid rgba(0, 0, 255, 0.3); */
    text-align: center;
    padding: 50px 20px;
    background-color: rgba(211, 211, 211, 0.2);
}

.categories h2 {
    margin-bottom: 30px;
    color: rgba(0, 0, 255, 0.5);
    font-size: 2rem;
}

.categories-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.category-card {
    background: #f8f8f8;
    border-radius: 10px;
    padding: 20px;
    width: calc(25% - 20px); /* Adjusting width to fit four in a row with spacing */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.category-card img {
    border-radius: 10px;
    width: 100%;
    height: auto;
}

.category-card h3 {
    margin: 15px 0 10px;
    color: rgba(0, 0, 255, 0.8);
    /* position: absolute; */
    /* top: 35%; */
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* left: 30%; */
}

.category-card p {
    color: #666;
    /* position: absolute; */
    /* top: 50%; */
    width: 80%;
    /* background-color: black; */
}

.category-card:hover {
    transform: translateY(-10px);
}

/* Responsive styling */
@media (max-width: 1200px) {
    .category-card {
        /* width: calc(25% - 20px); */
    }
}

@media (max-width: 768px) {
    .category-card {
        width: calc(50% - 20px); 
    }
}

@media (max-width: 480px) {
    .category-card {
        width: calc(50% - 10px); 
    }
    .category-card h3{
        font-size: 1rem;
    }
    .category-card p{
        font-size: 0.8rem;
    }
    .categories h2{
        font-size: 1.5rem;
    }
}
